<template>
  <div class="statistic-text-result">
    <a-modal :title="modalTitle"
             :visible="modalShown"
             :confirm-loading="confirmLoading"
             :width="650"
             :centered="true"
             :mask="true"
             :mask-closable="true"
             :destroy-on-close="true"
             :after-close="emitClose"
             @cancel="close"
             dialog-class="statistic-text-result-modal">
      <h3 class="row row-left no-padding mb-5 title">{{ title }}</h3>

      <h3 class="row row-left no-padding mb-5 mt-20 title">标准答案</h3>
      <div class="content">{{ content }}</div>

      <h3 class="row row-left no-padding mb-5 mt-20 title">学员作答</h3>
      <div v-if="isStudy" class="answer">学习模式下无个人作答内容</div>
      <div v-else class="answer info">
        <span v-for="(item, idx) of parsedAnswer" :key="idx" :class="[item.className]">{{ item.words }}</span>
      </div>

      <div slot="footer" class="row no-padding">
        <div class="row inline-row row-column row-left no-padding no-margin tip-text-lighter">
          <div class="col no-padding no-margin danger">*红色部分与标准话术不同，<span class="bold">加粗部分</span>为错的关键词要注意哦~</div>
          <div class="col no-padding no-margin info">蓝色部分为你答对的关键词，真棒！</div>
        </div>

        <a-button @click="close">关闭</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>

export default {
  name: 'StatisticTextResult',
  props: {
    data: {type: Object, default: () => new Object()},
    isStudy: {type: Boolean, default: false},
  },
  computed: {
    modalTitle() {
      return '查看文字对比结果';
    },
    title() {
      return this.data.title || '';
    },
    content() {
      return this.data.content || '';
    },
    parsedAnswer() {
      const parsed = [];
      const content = this.data.content_nofill || '';
      const contentArr = content.split('');

      let lastWordObj = {};

      let words = '';
      let className = '';

      for (let i = 0; i < contentArr.length; i++) {
        words = contentArr[i];
        if (this.redIndex.includes(i)) {
          className += 'danger ';
        }
        if (this.boldIndex.includes(i)) {
          className += 'bold ';
        }
        if (this.blueIndex.includes(i)) {
          className += 'info';
        }

        if (i === 0) {
          lastWordObj = {words: words, className: className};
        } else {
          if (lastWordObj.className === className) {
            lastWordObj.words += words;
          } else {
            parsed.push(lastWordObj);
            lastWordObj = {words: words, className: className};
          }

          if (i === contentArr.length - 1) {
            parsed.push(lastWordObj);
          }
        }

        words = '';
        className = '';
      }

      return parsed;
    },
    normalIndex() {
      const indexes = this.data?.red_pos_json?.length ? this.data.red_pos_json : [];
      indexes.sort();
      return indexes;
    },
    redIndex() {
      const indexes = [];
      const len = this.data.content_nofill.length || 0;

      for (let i = 0; i < len; i++) {
        if (!this.normalIndex.includes(i)) {
          indexes.push(i);
        }
      }
      indexes.sort();
      return indexes;
    },
    boldIndex() {
      const indexes = [];
      const content = this.data.content_nofill || '';
      const keywords = this.keywords;
      if (!this.isBold) {
        return [];
      }

      let currentIndex = 0;
      keywords.forEach((keyword) => {
        const arr = content.split(keyword);
        if (arr.length > 1) {
          arr.forEach((i, idx) => {
            if (idx === arr.length - 1) {
              return;
            }
            const start = i.length + currentIndex;
            indexes.push(...this.createRange(start, keyword.length));
            currentIndex = start + keyword.length;
          });
        }
        currentIndex = 0;
      });

      indexes.sort();
      return indexes;
    },
    blueIndex() {
      const indexes = this.data?.answer_keyword_json?.length ? this.data.answer_keyword_json : [];
      indexes.sort();
      return indexes;
    },
    isBold() {
      return true;
    },
    keywords() {
      return this.data?.keyword_title?.length ? this.data.keyword_title : [];
    },
  },
  data() {
    return {
      modalShown: true,
      confirmLoading: false,
    };
  },
  methods: {
    close() {
      this.modalShown = false;
    },
    emitClose() {
      this.$emit('close');
    },
    createRange(start, length) {
      const range = [];
      for (start; length > 0; length--) {
        range.unshift(start + length - 1);
      }
      return range;
    },
  },
};
</script>

<style lang="scss">
.statistic-text-result-modal {
  .ant-modal-body {
    min-height: 120px;
    padding: 12px 18px;
  }

  .content, .answer {
    font-size: 14px;
    color: $light05;
  }

  .bold {
    font-weight: 700;
  }

  .danger {
    color: $danger02;
  }

  .info {
    color: $info02;
  }
}
</style>
