<template>
  <div class="statistics-skill-query">
    <div v-if="rangePickerEnabled || skillTypeEnabled || examNameEnabled" class="flex flex-start flex-wrap">
      <SelectCategorizedSkill v-if="categorizedSkillEnabled"
                              :default-skill-cate="skillCate"
                              :default-skill-ids="skillId"
                              @select="selectCategorizedSkill"/>

      <div v-if="skillTypeEnabled" class="mb-10 flex-none skill-type-col">
        <SelectSkillType :default-selected-id="skillTypeId" @select="selectSkillType"></SelectSkillType>
      </div>

      <div v-if="rangePickerEnabled" class="col col-left mb-10 flex-none range-picker-col no-horizontal-padding">
        <span class="flex-none mr-5 width-6em text-right">选择日期：</span>
        <a-range-picker :allow-clear="true" v-model="queryDates" @change="dateRangeChange"></a-range-picker>
      </div>

      <div v-if="examNameEnabled" class="flex flex-start mb-10 flex-none ">
        <span class="flex-none mr-5 width-6em text-right">考核名称：</span>
        <a-input v-model="examName" placeholder="输入考核名称" class="exam-name-input" @change="examNameChange"></a-input>
      </div>
    </div>

    <!--没有用到考试状态-->
    <div v-if="examPassStatusEnabled" class="flex flex-start">
      <div v-if="examPassStatusEnabled"
           class="flex flex-start flex-none exam-pass-status-col no-horizontal-padding">
        <SelectExamPassStatus :default-selected-id="examPassStatus"
                              @select="selectExamPassStatus"></SelectExamPassStatus>
      </div>
    </div>

    <div class="flex flex-start flex-wrap">
      <div v-if="bigAreaEnabled" class="flex flex-start job-org-row big-area-col mb-10 mr-20">
        <span class="flex-none mr-5 width-6em text-right">选择大区：</span>
        <app-select-big-area :value="bigAreaIds"
                             :show-select-all-org="true"
                             mode="multiple"
                             @change="selectBigArea"/>
      </div>

      <div v-if="orgEnabled" class="flex flex-start mb-10 mr-20">
        <span class="flex-none mr-5 width-6em text-right">组织机构：</span>
        <app-select-org :value="orgIds"
                        :show-select-all-org="showSelectAllOrg"
                        @select="selectOrg"/>
      </div>

      <div v-if="showShopType" class="flex flex-start mb-10 mr-20">
        <span class="flex-none mr-5 width-6em text-right">店型：</span>
        <app-select-shop-type :value="shopType" @change="selectShopType"/>
      </div>

      <div v-if="jobEnabled" class="flex flex-start mb-10 mr-20">
        <span class="flex-none mr-5 width-6em text-right">岗位：</span>
        <app-select-job :value="jobIds" @select="selectJob"/>
      </div>

      <div v-if="filterEnabled" class="flex flex-start flex-none mb-10 mr-20">
        <span class="flex-none mr-5 text-right">姓名/电话/身份证号：</span>
        <a-input v-model="filterTxt"
                 allow-clear
                 placeholder="请输入姓名/电话/身份证号"
                 style="width: 280px;"
                 @change="filterChange"/>
      </div>
    </div>

    <div class="full-width flex flex-end mb-20">
      <a-button type="primary" @click="onSearch">查询</a-button>
      <a-button class="mr-30" @click="resetQuery">重置</a-button>
      <a-button type="primary" :loading="downloading" @click="exportExcel">导出</a-button>
      <a-button v-if="cancelEnabled" @click="cancel">返回</a-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import * as moment from 'moment';

import SelectCategorizedSkill from '@components/speechSkill/SelectCategorizedSkill';
import SelectExamPassStatus from '@components/speechSkill/SelectExamPassStatus';
import SelectSkillType from '@components/speechSkill/SelectSkillType';

export default {
  name: 'SkillQuery',
  components: { SelectCategorizedSkill, SelectExamPassStatus, SelectSkillType },
  props: {
    rangePickerEnabled: { type: Boolean, default: true },
    skillTypeEnabled: { type: Boolean, default: true },
    examNameEnabled: { type: Boolean, default: false },
    categorizedSkillEnabled: { type: Boolean, default: true },
    examPassStatusEnabled: { type: Boolean, default: true },
    filterEnabled: { type: Boolean, default: true },
    jobEnabled: { type: Boolean, default: true },
    bigAreaEnabled: { type: Boolean, default: true },
    orgEnabled: { type: Boolean, default: true },
    showSelectAllOrg: { type: Boolean, default: false },
    cancelEnabled: { type: Boolean, default: true },
    showShopType: { type: Boolean, default: false },

    downloading: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      queries: state => state.Statistics.speechSkillQuery,
    }),
  },
  watch: {
    queries(query) {
      this.init(query);
    },
  },
  data() {
    return {
      queryDates: [],
      skillTypeId: 1,
      examName: '',
      skillCate: [],
      skillId: undefined,
      jobIds: '',
      bigAreaIds: '',
      orgIds: '',
      examPassStatus: undefined,
      filterTxt: '',
      shopType: { store_state: 1 },
    };
  },
  created() {
    this.init(this.queries);
  },
  methods: {
    ...mapMutations({
      setSpeechSkillQuery(commit, query) {
        commit('Statistics/setSpeechSkillQuery', query);
      },
      resetSpeechSkillQuery(commit) {
        commit('Statistics/resetSpeechSkillQuery');
      },
    }),

    init(query) {
      const queryDates = [];
      queryDates[0] = query.startDateTime ? moment(query.startDateTime) : undefined;
      queryDates[1] = query.endDateTime ? moment(query.endDateTime) : undefined;
      this.queryDates = queryDates;
      this.skillTypeId = query?.skillType || undefined;
      const skillCate = [];
      skillCate[0] = query?.skillCate1 || undefined;
      skillCate[1] = query?.skillCate2 || undefined;
      this.skillCate = skillCate;
      this.skillId = query?.skill || undefined;
      this.examName = query?.examName || '';
      this.jobIds = query?.job ? (query.job + '') : '';
      this.bigAreaIds = query?.bigArea || '';
      this.orgIds = query?.org || '';
      this.examPassStatus = query?.passStatus || '';
      this.filterTxt = query?.filterTxt || '';
      this.shopType = query?.shopType || { store_state: 1 };
    },

    dateRangeChange(date, dateString) {
      this.setSpeechSkillQuery({
        startDateTime: dateString[0],
        endDateTime: dateString[1],
      });
    },

    selectSkillType(id) {
      this.setSpeechSkillQuery({ skillType: id });
    },

    examNameChange() {
      this.setSpeechSkillQuery({ examName: this.examName || '' });
    },

    selectCategorizedSkill(event) {
      this.resetSpeechSkillQuery();
      this.setSpeechSkillQuery({
        skillCate1: event?.skillCate1,
        skillCate2: event?.skillCate2,
        skill: event?.skillIds || '',
        skillList: event?.skills || [],
        job: event.post_ids || '',
      });
    },

    selectExamPassStatus(id) {
      this.setSpeechSkillQuery({ passStatus: id });
    },

    filterChange() {
      this.setSpeechSkillQuery({ filterTxt: this.filterTxt });
    },

    selectJob(event, list) {
      this.setSpeechSkillQuery({
        job: event && event.length ? event.join(',') : '',
        jobList: list?.length ? list : [],
      });
    },

    selectBigArea(ids, list) {
      this.setSpeechSkillQuery({
        bigArea: ids || '',
        bigAreaList: list || [],
      });
    },

    selectOrg(event) {
      const data = event?.data || [];
      this.setSpeechSkillQuery({
        org: data.map(i => i.id).join(','),
        orgList: data?.length ? data : [],
      });
    },

    selectShopType(event) {
      this.setSpeechSkillQuery({
        shopType: event,
      });
    },


    onSearch() {
      this.$emit('search');
    },
    resetQuery() {
      this.resetSpeechSkillQuery();
      this.$emit('reset');
    },
    exportExcel() {
      this.$emit('export');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped lang="scss">
.statistics-skill-query {
  width: 100%;

  .range-picker-col {
    width: 450px;
  }

  .skill-type-col {
    width: 450px;
  }

  .exam-name-input {
    width: 320px;
  }

  .job-col {
    padding-left: 2em;
  }

  .exam-pass-status-col {
    width: 260px;
  }

  //.filter-col {
  //  width: 380px;
  //}

  .job-org-row:not(.job-col):not(.big-area-col):first-child {
    padding-left: 0 !important;
  }

  .big-area-col {
    padding-left: 2em;
  }
}
</style>


