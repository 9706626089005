<!--带搜索的下拉选择框，选择单条话术检核-->

<template>
  <div class="ss-select-group flex flex-start mb-10">
    <span v-if="showLabel" class="flex-none mr-5 width-6em text-right">{{ label }}：</span>

    <a-select v-model="selectedId" :allow-clear="true" :show-search="true" :filter-option="false"
              :disabled="disabled" :placeholder="placeholder" class="select"
              @search="getGroupList" @change="select">
      <a-spin v-if="loading" slot="notFoundContent" size="small"/>

      <a-select-option v-for="item of options" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
    </a-select>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import debounce from 'lodash/debounce';

export default {
  name: 'SelectGroup',
  props: {
    label: {type: String, default: '话术检核'},
    placeholder: {type: String, default: '请输入检核名称，并在下拉列表中选择话术检核'},
    showLabel: {type: Boolean, default: true},
    defaultSelectedIds: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
  },
  computed: {
    ...mapState({
      options: state => state.Assessment.assessmentList,
    }),
  },
  watch: {
    defaultSelectedIds() {
      this.setDefault();
    },
  },
  data() {
    this.getGroupList = debounce(this.getGroupList, 500);
    return {
      loading: false,
      selectedId: undefined,
    }
  },
  created() {
    this.getGroupList();
    this.setDefault();
  },
  methods: {
    setDefault() {
      this.selectedId = +this.defaultSelectedIds || undefined;
    },

    getGroupList(value) {
      this.loading = true;
      this.$store.dispatch('Assessment/getAssessmentListForSelect', value)
        .finally(() => this.loading = false);
    },

    select(id) {
      const [item] = this.options.filter(i => +i.id === +id);
      this.$emit('select', item);
    },
  },
}
</script>

<style scoped lang="scss">
.ss-select-group {
  .select {
    min-width: 380px;
  }
}
</style>
