<template>
  <tab-detail-page :titles="titles" :menus="menus">
    <!--<select-people slot="extra"/>-->
  </tab-detail-page>
</template>

<script>
import { mapState } from 'vuex';
import TabDetailPage from '@components/TabDetailPage';
// import SelectPeople from '../components/SelectPeople';

const prefix = '/index/statistics/participation/student/';
const menus = [
  {
    id: '1',
    title: '完训统计',
    name: 'StatisticsParticipationStudentFinish',
    path: `${ prefix }finish`,
  },
  {
    id: '2',
    title: '考试统计',
    name: 'StatisticsParticipationStudentExam',
    path: `${ prefix }exam`,
  },
  {
    id: '3',
    title: '参训统计',
    name: 'StatisticsParticipationStudentParticipate',
    path: `${ prefix }participate`,
  },
];

const studentMenus = menus;
export { studentMenus };

export default {
  name: 'Index',
  components: { /*SelectPeople,*/ TabDetailPage },
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      titles: [{ title: '参训率查询' }, { title: '学员明细' }],
      menus,
    };
  },
};
</script>

<style scoped lang="scss">
.sps-index {
  width: 100%;
}
</style>
