<template>
  <div class="spo-participate">
    <participation-query ref="queryRef"
                         :show-course-and-exam="true"
                         :show-org="true"
                         :show-job="true"
                         :show-end-time="true"
                         :show-people-list="true"
                         :show-back="showBack"
                         :show-select-all-org="true"
                         :downloading="downloading"
                         course-type=""
                         class="mb-10"
                         @search="onSearch"
                         @reset="reset"
                         @export="onExport"/>

    <a-table :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="loading"
             bordered
             row-key="cusIndex"
             :scroll="{x: '100%'}"
             class="table small-cell-table">

      <Tooltip v-for="(column) in columns"
               :key="column.key"
               :slot="column.dataIndex"
               :title="column.describe">
        <span>{{ column.titleString }}</span>
      </Tooltip>

      <div v-for="(column) in columns"
           :key="column.key"
           :slot="column.dataIndex"
           slot-scope="text">
        <span>{{ text }}</span>
      </div>

      <a-button type="link"
                slot="name"
                slot-scope="text, record"
                class="full-width"
                style="white-space: pre-wrap;"
                @click="viewStudent(record)">
        {{ record.name }}
      </a-button>

      <template slot="footer">
        <Pagination v-if="pagination && pagination.total"
                    :pagination="pagination"
                    class="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"/>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Common from '@/utils/Common';
import {
  getParticipationOrgParticipate,
  getParticipationOrgParticipateDownload,
} from '@/api/statistics/participation/Org';

import Tooltip from '@/components/Tooltip';
import Pagination, { getPagination } from '@components/Pagination';
import ParticipationQuery from '../components/ParticipationQuery.vue';
import { studentMenus } from '../student/Index.vue';

export default {
  name: 'Participate',
  components: {
    Pagination,
    ParticipationQuery,
    Tooltip,
  },
  computed: {
    ...mapState({
      query: (state) => state.StatisticsParticipation.query,
    }),
  },
  data() {
    return {
      loading: false,
      downloading: false,
      showBack: false,
      fetchCount: 0,
      columns: [],
      data: [],
      pagination: {
        ...getPagination(),
        showSizeChanger: false,
        showTotal: () => '',
      },

      defaultOrg: '',
    };
  },
  created() {
    const defaultOrg = this.$route.query?.org_id || '';
    if (defaultOrg) {
      this.showBack = true;
      this.$store.commit('StatisticsParticipation/setQuery', {
        orgIds: `${defaultOrg}`,
      });
    }
  },
  mounted() {
    if (this.$refs.queryRef?.search) {
      this.$refs.queryRef.search(true);
    }
  },
  methods: {
    reset() {
      this.data = [];
      this.columns = [];
      this.pagination = {
        ...getPagination(),
        showSizeChanger: false,
        showTotal: () => '',
      };
    },
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = this.getParams(page, pageSize, false);

      const data = await getParticipationOrgParticipate(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        return;
      }
      const result = data?.data || [];
      this.data = result.map((i, idx) => {
        i.cusIndex = `${this.pagination.current}-${this.fetchCount}-${idx}`;
        return i;
      });

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
      const columns = data?.meta?.columns || [];
      columns.forEach((i) => {
        i.align = 'center';
        i.width = '120px';
        if (i.dataIndex === 'name') {
          i.width = '260px';
        }
        if (i.key === 'name') {
          i.scopedSlots = { customRender: i.key };
        }
        if (i.describe) {
          i.slots = { title: i.dataIndex };
          i.scopedSlots = { customRender: i.dataIndex };
          i.titleString = i.title;
          delete i.title;
        }
      });

      this.columns = columns;
      this.fetchCount += 1;
    },

    onExport() {
      if (this.downloading) {
        return;
      }
      this.downloading = true;

      const params = this.getParams(
        this.pagination.current,
        this.pagination.pageSize,
        true,
      );

      getParticipationOrgParticipateDownload(params).then(() => {
        this.downloading = false;
      }).catch(() => {
        this.downloading = false;
      });
    },

    getParams(page, pageSize, isDown) {
      const params = {
        page,
        per_page: pageSize,
      };

      if (this.query.peopleListId) {
        params.template_id = this.query.peopleListId;
      }
      if (this.query.endTime) {
        params.end_time = this.query.endTime.format('YYYY-MM-DD');
      }
      if (this.query.jobIds) {
        params.post_ids = this.query.jobIds;
      }
      if (this.query.orgIds) {
        params.org_ids = this.query.orgIds;
      }
      if (this.query.orgParams) {
        //  this.query.orgParams.forEach((s,idx)=>{
        //    params[idx] = this.query.orgParams;
        //  })
        for (const key in this.query.orgParams) {
          params[key] = this.query.orgParams[key];
        }
      }
      // if (this.query.courseAndExamIds) {
      //   params.course_ids = this.query.courseAndExamIds;
      // }
      if (this.query.courseIds) {
        params.course_ids = this.query.courseIds;
      }
      if (isDown) {
        params.down = 1;

        if (this.query.planIds) {
          params.plan_id = this.query.planIds;
        }
      }

      return params;
    },

    viewStudent(record) {
      const { path } = studentMenus[2];
      this.$router.push({
        path,
        query: {
          name_id: record.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.spo-participate {
  width: 100%;
}
</style>
