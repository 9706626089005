<template>
  <a-select v-model="selectedIds"
            :mode="mode"
            :allow-clear="true"
            :auto-clear-search-value="false"
            :show-search="true"
            :disabled="disabled"
            :placeholder="'请搜索' + placeholder"
            class="select"
            :filter-option="false"
            @focus="resetSearch"
            @blur="resetSearch"
            @popupScroll="onScroll"
            :maxTagCount="maxTagCount"
            @change="select"
            @search="onSearch">
    <!--<a-select-option :value="0" @click="selectAll">全选</a-select-option>-->
    <a-select-option v-for="(item) of filteredOptions"
                     :key="item.id"
                     :value="item.id">
      {{ item.title }}
    </a-select-option>
  </a-select>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SelectCourse',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    mode: { type: String, default: 'multiple' },
    type: {
      type: String,
      default: '',
      validator: (value) => ['course', 'exam', ''].includes(value),
    },
    maxTagCount: { type: Number, default: undefined },
  },
  computed: {
    ...mapState({
      options: (state) => state.StatisticsParticipation.taskList,
    }),
    placeholder() {
      return '课程';
      //   switch (this.type) {
      //     case 'course':
      //       return '课程';
      //     case 'exam':
      //       return '考试';
      //     default:
      //       return '课程或考试';
      //   }
    },
  },
  watch: {
    value(val, oldVal) {
      if ([...(val || [])].sort().join(',') === [...(oldVal || [])].sort().join(',')) {
        return;
      }
      this.setDefault();
    },
    options() {
      this.onSearch(this.filterTxt);
    },
  },
  data() {
    return {
      selectedIds: [],
      isAllClicked: false,

      page: 1,
      perPage: 50,
      filterTxt: '',
      filteredOptions: [],
    };
  },
  created() {
    this.$store.dispatch(
      'StatisticsParticipation/getParticipationTaskList',
      this.type,
    ).finally(() => {
      this.setDefault();
      this.onSearch('');
    });
  },
  methods: {
    resetSearch() {
      this.onSearch('');
    },
    onSearch(value) {
      this.filterTxt = value;
      this.page = 1;
      this.getFilteredOptions();
    },

    getFilteredOptions() {
      const ids = this.mode === 'multiple' ? this.selectedIds : [this.selectedIds];
      this.filteredOptions = this.options.filter((option, index) => {
        if (ids.map((i) => +i).indexOf(+option.id) > -1) {
          return true;
        }
        if (!this.filterTxt && index < this.page * this.perPage) {
          return true;
        }
        return this.filterTxt && option.title.indexOf(this.filterTxt) > -1;
      });
    },

    onScroll(ev) {
      const target = ev.currentTarget || ev.target || ev.srcElement;
      if (!target) {
        return;
      }

      const height = target.clientHeight || 250;
      const scrollTop = target.scrollTop || 0;
      const scrollHeight = target.scrollHeight || 250;

      if (
        scrollTop + 1.2 * height > scrollHeight &&
        this.page * this.perPage < this.options.length
      ) {
        this.loadMore();
      }
    },

    loadMore() {
      this.page += 1;
      this.getFilteredOptions();
    },

    selectAll() {
      this.isAllClicked = true;
    },

    setDefault() {
      if (!this.value) {
        this.ids = '';
        this.selectedIds = this.mode === 'multiple' ? [] : undefined;
        this.select();
        return;
      }
      const ids = this.value.split(',');
      this.selectedIds = this.mode === 'multiple' ? ids.map((i) => +i) : +ids[0];
      this.getFilteredOptions();
      this.select();
    },

    select() {
      if (!this.options || !this.options.length) {
        return;
      }

      // 点击全选按钮全选/取消全选全部选项
      // if (this.isAllClicked) {
      //   if (this.selectedIds && this.selectedIds.includes(0)) {
      //     this.selectedIds = this.options.map(op => op.id);
      //     this.selectedIds.unshift(0);
      //   } else {
      //     this.selectedIds = [];
      //   }
      // }

      if (this.isAllClicked) {
        this.selectedIds = this.mode === 'multiple' ? this.options.map((op) => op.id) : this.options[0].id;
        if (this.mode === 'multiple') {
          this.selectedIds.unshift(0);
        }
      }

      this.isAllClicked = false;

      const ids = this.mode === 'multiple' ? this.selectedIds.filter((i) => !!i) : [this.selectedIds];
      const items = this.options.filter((op) => ids.includes(op.id));
      if (this.value.split(',').filter(
        (i) => !!i,
      ).sort(
        (a, b) => +a - +b,
      ).join(',') !== ids.filter(
        (i) => !!i,
      ).sort(
        (a, b) => +a - +b,
      ).join(',')) {
        this.$emit('change', ids.join(','), items);
      }
    },
  },
};
</script>

<style scoped lang="less">
.select {
  min-width: 420px;

  ::v-deep .ant-select-selection {
    overflow-y: auto;
    max-height: 120px;
  }
}
</style>
