<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Statistics',
  beforeRouteLeave(to, from, next) {
    next(vm => {
      vm.$store.Statistics.resetSpeechSkillQuery();
      vm.$store.Statistics.resetSpeechGroupQuery();
    });
  },
  created() {
    this.$store.dispatch('Common/getScriptStatus');
    this.$store.dispatch('Common/getScriptCategories');
    this.$store.dispatch('Common/getStoreType');
  },
};
</script>

<style scoped lang="scss">

</style>
