import { stringify } from 'qs';
import { baseUrl } from '@/Config';
import store from '@/store/index';

import request from '@/utils/Request';
import { downloadFileExtra } from '@/utils/download';

// 课程学习人次统计列表
export function getVisitPersonTime(params) {
  return request({
    url: '/course/admin/statisticalIndex',
    method: 'get',
    params,
  });
}

// 课程学习人次统计列表 -- 导出
export function getVisitPersonTimeDownload(params) {
  let url = '/course/admin/statisticalIndex';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

