<template>
  <div class="ss-statistics-skill-detail">
    <CommonTitle :titles="titles"></CommonTitle>

    <div class="row row-left">
      <a-menu v-model="selectedMenu" mode="horizontal" @select="selectMenu">
        <a-menu-item v-for="item of menus" :key="item.id">
          {{ item.title }}
        </a-menu-item>
      </a-menu>
    </div>

    <div class="row">
      <router-view/>
    </div>
  </div>
</template>

<script>
import {statisticsSkillDetail} from '@/utils/Navs/StatisticsNavs';
import CommonTitle from '@components/CommonTitle';

export default {
  name: 'StatisticsSkillDetail',
  components: {CommonTitle},
  computed: {},
  data() {
    return {
      titles: [{title: '话术管理'}, {title: '数据统计'}, {title: '话术查询'}],
      menus: statisticsSkillDetail,
      selectedMenu: ['1'],
    }
  },
  created() {
  },
  destroyed() {
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.executeSelection(to?.name || ''));
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.query?.id || null;
    this.executeSelection(to?.name || '');
    next();
  },
  methods: {
    executeSelection(name) {
      this.menus.forEach(menu => {
        if (menu.name === name) {
          this.selectedMenu = [menu.id];
        }
      });
    },
    selectMenu({key}) {
      const path = this.getPath(key) + (this.id ? `?id=${this.id}` : '');
      this.$router.replace(path);
    },
    getPath(key) {
      const [{path}] = this.menus.filter(menu => menu.id === key + '' && menu.path);
      return path || '';
    },
  }
}
</script>

<style scoped lang="scss">
.ss-statistics-skill-detail {
  .sub-txt {
    font-size: 12px;
    color: $light02;
  }
}
</style>
