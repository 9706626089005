<template>
  <div>
    <div class="flex flex-start flex-wrap ph-20 mb-10">
      <div class="flex mr-30 mb-10">
        <span class="flex-none">课程上线时间：</span>
        <a-range-picker v-model="form.pullOnTime"/>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">课程学习时间：</span>
        <a-range-picker v-model="form.studyTime"/>
      </div>
    </div>
    <div class="flex flex-end flex-wrap ph-20 mb-10">
      <a-button type="primary" @click="onSearch()">查询</a-button>
      <a-button @click="onResetQuery()">重置</a-button>
      <a-button @click="onExportExcel()">导出</a-button>
    </div>

    <div class="mb-10 ph-20" style="text-align: center">
      <template v-if="totalCourseNum || totalPersonTime">
        <span>上线课程：</span>
        <span class="stress-text">{{ totalCourseNum }}</span>
        <span>门；学习情况：</span>
        <span class="stress-text">{{ totalPersonTime }}</span>
        <span>人次。</span>
      </template>
      <span v-else>数据汇总：暂无</span>
    </div>

    <div class="ph-20">
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="fetching"
               bordered
               row-key="rowIndex"
               class="table small-cell-table">
        <Tooltip slot="course_study_user_num" title="学员点击课件学习则算一次">
          学习人次
        </Tooltip>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import table from '@/mixins/table';

import {
  getVisitPersonTime,
  getVisitPersonTimeDownload,
} from './api';

import Tooltip from '@/components/Tooltip';

const defaultForm = {
  pullOnTime: [undefined, undefined],
  studyTime: [undefined, undefined],
};
const columns = [
  {
    width: '80px',
    align: 'center',
    title: '序号',
    dataIndex: 'rowIndex',
    key: 'rowIndex',
  },
  {
    width: '240px',
    align: 'center',
    title: '课程名',
    dataIndex: 'title',
    key: 'title',
  },
  {
    width: '170px',
    align: 'center',
    title: '创建时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
  },
  {
    width: '170px',
    align: 'center',
    title: '上架时间',
    dataIndex: 'shutup_time',
    key: 'shutup_time',
  },
  {
    width: '170px',
    align: 'center',
    title: '学习时间',
    dataIndex: 'day',
    key: 'day',
  },
  {
    width: '120px',
    align: 'center',
    // title: '学习人次',
    dataIndex: 'course_study_user_num',
    key: 'course_study_user_num',
    slots: { title: 'course_study_user_num' },
  },
];

export default {
  name: 'VisitPersonTime',
  components: {
    Tooltip,
  },
  mixins: [table],
  data() {
    return {
      originColumns: columns,
      defaultForm,
      form: { ...defaultForm },

      totalCourseNum: 0,
      totalPersonTime: 0,
    };
  },
  methods: {
    onResetQuery() {
      this.totalCourseNum = 0;
      this.totalPersonTime = 0;

      this.onReset(false);
    },
    getData(page, pageSize) {
      if (!this.form.pullOnTime?.[0] || !this.form.pullOnTime?.[1]) {
        this.$message.info('请选择课程上线时间');
        return Promise.reject(new Error('请选择课程上线时间'));
      }

      this.executeGetData(async () => {
        const params = {
          page,
          per_page: pageSize,
          online_start_time: this.form.pullOnTime?.[0]?.format('YYYY-MM-DD') || '',
          online_end_time: this.form.pullOnTime?.[1]?.format('YYYY-MM-DD') || '',
          study_start_time: this.form.studyTime?.[0]?.format('YYYY-MM-DD') || '',
          study_end_time: this.form.studyTime?.[1]?.format('YYYY-MM-DD') || '',
        };
        const data = await getVisitPersonTime(params);
        if (!data || data.error_code) {
          this.$message.error(data?.message || '获取数据失败');
          return Promise.reject(data);
        }
        this.data = (data.data || []).map((i, index) => ({
          ...i,
          rowIndex: (page - 1) * 10 + (index + 1),
        }));
        this.totalCourseNum = data.meta.online_course_num;
        this.totalPersonTime = data.meta.course_study_user_num;
        return Promise.resolve(data);
      });
    },

    onExportExcel() {
      if (!this.form.pullOnTime?.[0] || !this.form.pullOnTime?.[1]) {
        this.$message.info('请选择课程上线时间');
        return Promise.reject(new Error('请选择课程上线时间'));
      }

      this.executeExportExcel(async () => {
        const params = {
          page: this.pagination.current,
          per_page: this.pagination.pageSize,
          online_start_time: this.form.pullOnTime?.[0]?.format('YYYY-MM-DD') || '',
          online_end_time: this.form.pullOnTime?.[1]?.format('YYYY-MM-DD') || '',
          study_start_time: this.form.studyTime?.[0]?.format('YYYY-MM-DD') || '',
          study_end_time: this.form.studyTime?.[1]?.format('YYYY-MM-DD') || '',
          is_down: 1,
        };
        const data = await getVisitPersonTimeDownload(params);

        if (!data || data?.error_code) {
          return Promise.reject(data);
        }
        return Promise.resolve(data);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.stress-text {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 700;
  color: #f5222d;
}
</style>
