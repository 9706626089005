<template>
  <div class="flex flex-start mb-10 ss-select flex-column-center ss-select-exam-type">
    <span v-if="showLabel" class="flex-none mr-5 width-6em text-right">考试阶段：</span>

    <a-select v-model="selectedId" :disabled="disabled" placeholder="请选择考试阶段" class="select"
              @change="select">
      <a-select-option v-for="item of list" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
    </a-select>
  </div>
</template>

<script>

const list = [
  {id: 0, name: '全部'},
  {id: 1, name: '常规'},
  {id: 2, name: '补考'},
];

export default {
  name: 'SelectExamType',
  props: {
    showLabel: {type: Boolean, default: true},
    defaultSelectedId: {type: [Number], default: undefined},
    disabled: {type: Boolean, default: false},
  },
  watch: {
    defaultSelectedId() {
      this.setDefault();
    },
  },
  data() {
    return {
      selectedId: 0,
      list: list,
    }
  },
  created() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      if (!this.defaultSelectedId) {
        return;
      }
      this.selectedId = +this.defaultSelectedId || undefined;
      this.select();
    },
    select() {
      if (!this.list || !this.list.length || this.selectedId === undefined || this.selectedId === null) {
        return;
      }
      this.$emit('select', this.selectedId || undefined);
    },
  }
}
</script>

<style scoped lang="scss">
.ss-select-exam-type {
  .select {
    min-width: 180px;
    max-width: 180px;
  }
}
</style>
