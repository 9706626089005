<!--带搜索的下拉选择框，选择单条话术-->

<template>
  <div class="flex flex-start ss-select ss-select-script">
    <span v-if="showLabel" class="flex-none mr-5 width-6em text-right">单条话术：</span>

    <a-select v-model="selectedId" :allow-clear="true" :show-search="true" :filter-option="false"
              :disabled="disabled" placeholder="请输入题干关键字，并在下拉列表中选择话术"
              :mode="mode"
              class="select"
              @search="getSkillList" @change="select">
      <a-spin v-if="loading" slot="notFoundContent" size="small"/>

      <a-select-option v-for="item of options" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
    </a-select>
  </div>
</template>


<script>
import {mapState} from 'vuex';
import debounce from 'lodash/debounce';

export default {
  name: 'SelectSkill',
  props: {
    showLabel: {type: Boolean, default: true},
    defaultSelectedIds: {type: String, default: undefined},
    cate1: {type: Number, default: undefined},
    cate2: {type: Number, default: undefined},
    disabled: {type: Boolean, default: false},
    mode: {type: String, default: 'default', validator: (i) => ['default', 'multiple'].includes(i)},
  },
  computed: {
    ...mapState({
      options: state => state.Script.scriptList,
    }),
    isMultiple() {
      return this.mode === 'multiple';
    },
  },
  watch: {
    'cate1'() {
      this.resetSelected();
      this.getSkillList('');
    },
    'cate2'() {
      this.resetSelected();
      this.getSkillList('');
    },
  },
  created() {
    this.getSkillList('');
  },
  data() {
    this.getSkillList = debounce(this.getSkillList, 500);
    return {
      loading: false,
      selectedId: undefined,
    };
  },
  methods: {
    resetSelected(ids) {
      const list = ids && ids.length ? ids.split(',') : [];
      if (this.isMultiple) {
        this.selectedId = list.map(i => +i);
      } else {
        this.selectedId = +ids ? +ids : undefined;
      }
    },

    getSkillList(value) {
      this.loading = true;
      this.$store.dispatch('Script/getScriptListForSelect', {
        value,
        cate1: this.cate1,
        cate2: this.cate2,
      }).finally(() => this.loading = false);
      this.$nextTick(() => {
        if (this.selectedId && this.selectedId.length) {return;}
        if (this.defaultSelectedIds && this.options.some(o => {
          const list = this.defaultSelectedIds ? this.defaultSelectedIds.split(',') : [];
          return list.includes(o.id + '');
        })) {
          this.resetSelected(this.defaultSelectedIds);
        }
      });
    },

    select(id) {
      const items = this.options.filter(o => this.isMultiple ? this.selectedId.includes(o.id) : o.id === id);
      this.$emit('select', items);
    },
  },
};
</script>

<style scoped lang="scss">
.ss-select-script {
  .select {
    min-width: 360px;
  }
}
</style>
